import { MessagePage } from "components/shared/pageLayouts/MessagePage";

export const NotFoundPage = () => {
  return (
    <MessagePage>
      <h2>404: Page Not Found 😕</h2>
      <h5>
        Oops! The Lumo page you're trying to access isn't available.
        <br />
        It might have been removed, or the link could be incorrect.
      </h5>

      <div className="ls-info">
        <p>What can you do?</p>
        <ul>
          <li>Double-check the link and try again.</li>
          <li>Contact the creator for more information.</li>
        </ul>
      </div>
    </MessagePage>
  );
};
