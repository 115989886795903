export const MAIN_URL = "https://lumopages.com/";
export const API_URL = "https://api.lumopages.com/";
export const APP_URL = "https://app.lumopages.com/";

export const DISPLAY_TOOLS = [
  {
    id: 14,
    name: "rich text",
    desc: "use rich text editor",
    icon: "textIcon",
  },
  {
    id: 10,
    name: "image",
    desc: "add image",
    label: true,
    labelDesc: true,
    icon: "imageIcon",
  },

  {
    id: 12,
    name: "video",
    desc: "embed video",
    label: true,
    labelDesc: false,
    icon: "videoIcon",
  },

  {
    id: 11,
    name: "gallery",
    desc: "add images",
    label: true,
    labelDesc: true,
    icon: "galleryIcon",
  },

  // CURRENTLY NOT AVAILABLE
  // {
  //   id: 13,
  //   name: "layout",
  //   desc: "orgenize your layout",
  //   icon: "splitRowIcon",
  // },
  // {
  //   id: 1,
  //   name: "title",
  //   desc: "add text",
  //   label: true,
  //   labelDesc: true,
  //   icon: "titleIcon",
  // },
];

export const FORM_TOOLS = [
  {
    id: 2,
    name: "text",
    desc: "one line text input",
    label: true,
    labelDesc: true,
    types: ["text", "number", "email"],
    allowRequiered: true,
    icon: "textIcon",
  },
  {
    id: 3,
    name: "text area",
    desc: "long text input",
    lines: 4,
    label: true,
    labelDesc: true,
    allowRequiered: true,
    icon: "textAreaIcon",
  },
  {
    id: 4,
    name: "radio group",
    desc: "select on of options",
    label: true,
    labelDesc: true,
    allowRequiered: true,
    icon: "radioIcon",
  },
  {
    id: 5,
    name: "dropdown",
    desc: "select from options",
    label: true,
    labelDesc: true,
    types: ["single", "multiple"],
    allowRequiered: true,
    icon: "dropdownIcon",
  },
  {
    id: 6,
    name: "checkbox",
    desc: "check box",
    label: true,
    labelDesc: true,
    types: ["box", "toggle"],
    allowRequiered: true,
    icon: "checkboxIcon",
  },
  {
    id: 7,
    name: "date",
    desc: "date and time selector",
    label: true,
    labelDesc: true,
    types: ["date", "time", "datetime"],
    allowRequiered: true,
    icon: "dateIcon",
  },
];

export const TOOLS = [...DISPLAY_TOOLS, ...FORM_TOOLS];

export const BUILD_PARTS = {
  header: {
    key: "header",
    name: "Header",
    desc: "Lorem ipsum",
  },
  footer: {
    key: "footer",
    name: "Footer",
    desc: "Lorem ipsum",
  },
  success: {
    key: "success",
    name: "Success Page",
    desc: "Lorem ipsum",
  },
  error: {
    key: "error",
    name: "Error Page",
    desc: "Lorem ipsum",
  },
};
