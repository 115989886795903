import { Field } from "react-final-form";
import { ButtonsSelect, FormField } from "./common-styles";
import { Button } from "semantic-ui-react";

export const FieldButtonSelect = ({
  label,
  className = "",
  finalFormProps,
  options,
  multiple = false,
  initialValue,

  buttonProps = {
    className: "ls-bright",
    color: "grey",
  },
}) => {
  const initValue = initialValue || multiple ? [] : "";
  return (
    <Field {...finalFormProps}>
      {({ meta, input }) => {
        const hasError =
          meta.touched &&
          (meta.error || (meta.submitError && !meta.modifiedSinceLastSubmit));

        const isActive = (value) =>
          multiple ? input.value.includes(value) : input.value === value;

        const handleClick = (value) => {
          if (isActive(value)) {
            if (multiple) {
              input.onChange(input.value.filter((val) => val !== value));
            } else {
              input.onChange(null);
            }
          } else {
            if (multiple) {
              input.onChange([...input.value, value]);
            } else {
              input.onChange(value);
            }
          }
        };

        return (
          <FormField className={className}>
            <label>{label}</label>
            <input
              name={input.name}
              value={input.value || initValue}
              type="hidden"
            />
            <ButtonsSelect>
              {options.map((option) => {
                if (!option.content) return null;
                const isOptionActive = isActive(option.value);
                const { className, ...otherProps } = buttonProps;
                const classes =
                  (className || "") + (isOptionActive ? " ls-active" : "");
                return (
                  <Button
                    key={option.key}
                    type="button"
                    className={classes}
                    onClick={() => handleClick(option.value)}
                    {...otherProps}
                  >
                    {option.content}
                  </Button>
                );
              })}
            </ButtonsSelect>

            {hasError && (
              <p className="ls-error">{meta.error || meta.submitError}</p>
            )}
          </FormField>
        );
      }}
    </Field>
  );
};
