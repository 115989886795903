import { MessagePage } from "components/shared/pageLayouts/MessagePage";

export const ErrorPage = () => {
  return (
    <MessagePage>
      <h2>Something Went Wrong 😕</h2>
      <h5>We're working to fix it as quickly as possible.</h5>

      <div className="ls-info">
        <p>What can you do?</p>
        <ul>
          <li>Try refreshing the page.</li>
          <li>If the issue persists, please contact support.</li>
        </ul>
      </div>
      <br />
      <p>We apologize for the inconvenience and appreciate your patience!</p>
    </MessagePage>
  );
};
