import { MessagePage } from "components/shared/pageLayouts/MessagePage";

export const LimitReachedPage = () => {
  return (
    <MessagePage>
      <h2>This Lumo is Currently Unavailable 😕</h2>
      <h5>We're sorry, but this Lumo cannot be accessed right now.</h5>

      <div className="ls-info">
        <p>What can you do?</p>
        <ul>
          <li>Please try again later.</li>
          <li>Contact the creator for more information.</li>
        </ul>
      </div>
    </MessagePage>
  );
};
