import { API_URL } from "util/consts";

const createHeaders = (isFile = false) => {
  const token = localStorage.getItem("token");
  const headers = {
    Accept: "application/json",
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
  };

  if (!isFile) {
    headers["Content-Type"] = "application/json";
  }

  return headers;
};

const GET = async (endpoint, params) => {
  let paramsUri = "";

  if (params) {
    paramsUri =
      "?" +
      Object.entries(params)
        .map(([key, value]) => {
          if (Array.isArray(value)) {
            return value.map((item) => `${key}[]=${item}`).join("&");
          }
          return `${key}=${value}`;
        })
        .join("&");
  }
  return fetch(`${API_URL}${endpoint}${paramsUri}`, {
    method: "GET",
    headers: createHeaders(),
  }).then((response) => response.json());
};

const POST = async (endpoint, body = {}, isFile = false) => {
  return fetch(`${API_URL}${endpoint}`, {
    method: "POST",
    headers: createHeaders(isFile),
    body: isFile ? body : JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.status === "success") {
        return response.data;
      } else {
        return response;
      }
    });
};

// eslint-disable-next-line
const PUT = async (endpoint, id, body) => {
  return fetch(`${API_URL}${endpoint}/${id || ""}`, {
    method: "PUT",
    headers: createHeaders(),
    body: JSON.stringify(body),
  })
    .then((response) => response.json())
    .then((response) => {
      if (response.status === "success" || response.data) {
        return response.data;
      } else {
        console.error(response);
      }
    });
};
// eslint-disable-next-line
const DELETE = (endpoint, id) => {
  return fetch(`${API_URL}${endpoint}/${id}`, {
    method: "DELETE",
    headers: createHeaders(),
  });
};

export const auth = {
  login: (data) => {
    return POST("login", data);
  },

  loginGoogle: (data) => {
    return POST("auth/google", data);
  },

  registerGoogle: (data) => {
    return POST("auth/google/complete-registration", data);
  },

  register: (data) => {
    return POST("register", data);
  },

  requestEmailVerification: (data) => {
    return POST("resend-verification-email", data);
  },

  registerVerification: (data) => {
    return POST("register-verification", data);
  },

  requestResetPassword: (data) => {
    return POST("password/request-reset", data);
  },

  resetPassword: (data) => {
    return POST("password/reset", data);
  },

  logout: () => {
    return POST("logout");
  },

  getUserData: () => {
    return GET("user");
  },

  updatePersonalData: (data) => {
    return PUT("user/personal-data", null, data);
  },

  updatePassword: (data) => {
    return PUT("user/password", null, data);
  },

  uploadAvatar: (data) => {
    return POST("user/avatar/upload", data, true);
  },

  deleteAvatar: () => {
    return DELETE("user/avatar", "");
  },
};

export const forms = {
  get: (params) => {
    return GET("forms", params);
  },

  create: (data) => {
    return POST("forms", data);
  },

  update: (id, data) => {
    return PUT("forms", id, data);
  },

  delete: (id) => {
    return DELETE("forms", id);
  },
};

export const projects = {
  get: (params) => {
    return GET("projects", params);
  },

  getById: (id) => {
    return GET(`projects/${id}`);
  },

  create: (data) => {
    return POST("projects", data);
  },

  update: (id, data) => {
    return PUT("projects", id, data);
  },

  delete: (id) => {
    return DELETE("projects", id);
  },
};

export const media = {
  get: () => {
    return GET("media");
  },

  add: (data) => {
    return POST("media/add", data, true);
  },

  delete: (id) => {
    return DELETE(`media`, id);
  },
};

export const replies = {
  get: (params) => {
    return GET("replies", params);
  },

  getById: (id) => {
    return GET(`replies/${id}`);
  },

  getByProject: (projectId) => {
    return GET(`replies/project/${projectId}`);
  },

  delete: (id) => {
    return DELETE(`replies`, id);
  },
};

export const publicEndpoints = {
  getPage: (username, projectSlug) => {
    return GET(`live/projects/${username}/${projectSlug}`);
  },
  sendReply: (username, projectSlug, data) => {
    return POST(`live/projects/${username}/${projectSlug}`, {
      response: JSON.stringify(data),
    });
  },
};

export const payment = {
  createSubscription: (data) => {
    return POST(`paypal/subscribe`, data);
  },
};
