export const multiValidators = (arr) => (value, allValues, meta) => {
  for (const validator of arr) {
    let res = validator(value, allValues, meta);
    if (res) return res;
  }
  return undefined;
};

export const required = (value) => (value ? undefined : `Required field`);

export const requiredCheck = (value) => {
  return value ? undefined : `Required check`;
};

export const number = (value) =>
  typeof value === "number" ? undefined : `Field must be a number`;

export const min = (num) => (value) =>
  value.length >= num ? undefined : `Field must be at least ${num} charecters`;

export const max = (num) => (value) =>
  value.length <= num
    ? undefined
    : `Field can't be longer than ${num} charecters`;

export const equals = (field) => (value, allValues) =>
  value === allValues[field] ? undefined : `Field must be the same as ${field}`;

export const email = (value) =>
  value && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
    ? undefined
    : "Field must be a valid email";

export const maxFileSize = (mbSize) => (file) =>
  file.size <= mbSize * 1024 * 1024
    ? undefined
    : `File size exceeds ${mbSize} MB`;

export const fileTypes = (allowedTypes) => (file) =>
  allowedTypes.includes(file.type) ? undefined : `Invalid file type`;
