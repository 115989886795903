import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { publicEndpoints } from "structure/endpoints";
import { Content } from "./Content";
import { FullPageLoading } from "components/shared/Loading";
import { LimitReachedPage } from "components/LimitReachedPage";
import { NotFoundPage } from "components/NotFoundPage";
import { ErrorPage } from "components/ErrorPage";

export const Website = () => {
  const { username, projectSlug } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isNotFound, setIsNotFound] = useState(false);
  const [isLimitReached, setIsLimitReached] = useState(false);
  const [data, setData] = useState(null);
  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      publicEndpoints
        .getPage(username, projectSlug)
        .then((res) => {
          if (res.error) {
            throw res;
          }
          setData(res);
        })
        .catch((e) => {
          if (e.code === "LR8") {
            setIsLimitReached(true);
          } else {
            setIsNotFound(true);
          }
        })
        .finally(() => setIsLoading(false));
    }, 1500);

    // eslint-disable-next-line
  }, []);

  if (isLoading) return <FullPageLoading />;

  if (isNotFound) return <NotFoundPage />;

  if (isLimitReached) return <LimitReachedPage />;

  if (!data) return <ErrorPage />;

  return <Content data={data} />;
};
