export const basicTemplate = `
    h1 {
        color: var(--grey-800);
    }
    h2 {
        color: var(--grey-700);
    }
    header {
        border-bottom: 1px solid var(--grey-200);
    }
    footer {
        background: var(--primary-100);
    }

    .ls-frame {
        background: var(--primary-50);
        padding: 16px 24px;
        border-radius: 4px;
        box-shadow: var(--shadow-200);
    }
`;
